<template>
  <div class="adminannouncement">
    <p class="adan-txt">
      Click to read our newest
      <span><a href="#">feature announcements!</a></span>
    </p>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
