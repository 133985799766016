<template>
  <li class="adminmenu-item" :class="itemClasses">
    <a href="{{ link }}" class="adminmenu-link">
      <span class="navlink-ic">
        <span v-if="unreadNotification" class="navlink-ic unreadMsg"
          ><slot name="icon"></slot
        ></span>
        <slot v-else name="icon"></slot
        >
      </span>
      <span class="navlink-itm">{{ label }}</span></a
    >
  </li>
</template>

<script setup>
import { defineProps, useSlots } from "vue";
const props = defineProps({
  itemClasses: String,
  link: String,
  label: String,
  unreadNotification: Boolean,
});

const slots = useSlots();
</script>

<style lang="scss" scoped></style>
