<template>
  <footer class="footer">
    <p class="copyright-txt">
      Copyright © <span><a href="/">FitPath</a></span> {{ currentYear }}<br />
      Support: <a href="tel:+447852410606">+447852410606</a>
    </p>
    <img src="../assets/logo.svg" width="31" class="ftrLogo" alt="FitPath" />
    <div
      class="footerAddItemsWrap mobile-only"
      :class="{ showAddItemsWrap: isAddItemClicked }"
    >
      <div class="ftrAddNewItem">
        <div class="adminadd-item">
          <h4 class="adminadd">
            <i class="fas fa-plus-circle"></i><span>Add Client</span>
          </h4>
          <span class="admad-ico"><i class="fas fa-user"></i></span>
          <a href="#" class="adminaddnewlink">&nbsp;</a>
        </div>
      </div>
      <div class="ftrAddNewItem">
        <div class="adminadd-item">
          <h4 class="adminadd">
            <i class="fas fa-plus-circle"></i><span>Add new Exercise</span>
          </h4>
          <span class="admad-ico"><i class="fas fa-running"></i></span>
          <a href="#" class="adminaddnewlink">&nbsp;</a>
        </div>
      </div>
      <div class="ftrAddNewItem">
        <div class="adminadd-item">
          <h4 class="adminadd">
            <i class="fas fa-plus-circle"></i><span>Add Workout Plan</span>
          </h4>
          <span class="admad-ico"><i class="fas fa-map"></i></span>
          <a href="#" class="adminaddnewlink">&nbsp;</a>
        </div>
      </div>
      <div class="ftrAddNewItem">
        <div class="adminadd-item">
          <h4 class="adminadd">
            <i class="fas fa-plus-circle"></i><span>Add Video</span>
          </h4>
          <span class="admad-ico"><i class="fas fa-video"></i></span>
          <a href="#" class="adminaddnewlink">&nbsp;</a>
        </div>
      </div>
      <div class="ftrAddNewItem">
        <div class="adminadd-item">
          <h4 class="adminadd">
            <i class="fas fa-plus-circle"></i><span>Add Recipe</span>
          </h4>
          <span class="admad-ico"
            ><svg
              width="45"
              height="45"
              viewBox="0 0 39 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.2178 17.4219C35.8472 17.8385 35.4641 18.2812 35.0688 18.75C34.6734 19.2188 34.173 19.8438 33.5676 20.625C32.9621 21.4062 32.4741 22.1875 32.1034 22.9688C31.7328 23.75 31.5474 24.4271 31.5474 25C31.5474 25.7031 30.3922 26.2956 28.0817 26.7773C25.7712 27.2591 22.9727 27.5 19.6861 27.5C16.3995 27.5 13.601 27.2591 11.2905 26.7773C8.98004 26.2956 7.8248 25.7031 7.8248 25C7.8248 24.2448 7.4912 23.3008 6.824 22.168C6.1568 21.0352 5.53285 20.1237 4.95214 19.4336C4.37143 18.7435 3.79689 18.099 3.22854 17.5C1.54818 15.7812 0.708008 13.6979 0.708008 11.25C0.708008 8.82812 1.5173 6.76432 3.13587 5.05859C4.75445 3.35286 6.7128 2.5 9.01093 2.5C9.57929 2.5 10.1724 2.5651 10.7901 2.69531C10.3948 3.76302 10.1971 4.94792 10.1971 6.25C10.1971 9.03646 11.0125 11.3542 12.6435 13.2031C12.594 11.7969 12.5693 10.3125 12.5693 8.75C12.5693 6.32812 13.2612 4.26432 14.6451 2.55859C16.0289 0.852865 17.7092 0 19.6861 0C21.663 0 23.3434 0.852865 24.7272 2.55859C26.111 4.26432 26.8029 6.32812 26.8029 8.75C26.8029 10.3385 26.7782 11.8229 26.7288 13.2031C28.3597 11.3542 29.1752 9.03646 29.1752 6.25C29.1752 4.94792 28.9775 3.76302 28.5821 2.69531C29.1999 2.5651 29.793 2.5 30.3613 2.5C32.6594 2.5 34.6178 3.35286 36.2364 5.05859C37.8549 6.76432 38.6642 8.82812 38.6642 11.25C38.6642 13.6719 37.8488 15.7292 36.2178 17.4219ZM19.6676 30C22.9418 30 25.7403 29.7526 28.0632 29.2578C30.386 28.763 31.5474 28.1771 31.5474 27.5V37.5C31.5474 38.1771 30.386 38.763 28.0632 39.2578C25.7403 39.7526 22.9418 40 19.6676 40C16.3934 40 13.601 39.7526 11.2905 39.2578C8.98004 38.763 7.8248 38.1771 7.8248 37.5V27.5C7.8248 28.2031 8.98004 28.7956 11.2905 29.2773C13.601 29.7591 16.3934 30 19.6676 30Z"
                fill="#E9E9E9"
              />
            </svg> </span
          ><a href="#" class="adminaddnewlink">&nbsp;</a>
        </div>
      </div>
    </div>
    <div class="adminFooterNav">
      <div class="adminFtrCol">
        <a href="#" class="adminftrLnk"
          ><span class="adminftrIco"><i class="fas fa-users"></i></span
          ><span class="adminftrtXT">Clients</span></a
        >
      </div>
      <div class="adminFtrCol">
        <a href="#" class="adminftrLnk"
          ><span class="adminftrIco"><i class="fas fa-calendar-check"></i></span
          ><span class="adminftrtXT">Check-ins</span></a
        >
      </div>
      <div class="adminFtrCol">
        <a
          href="javascript:void(0);"
          class="adminftrLnk addNewItemBtn"
          @click="addItemHandler"
          ><span class="adminPlusIco"><i class="fas fa-plus-circle"></i></span
          ><span class="adminftrtXT">Add new...</span></a
        >
      </div>
      <div class="adminFtrCol">
        <a href="#" class="adminftrLnk"
          ><span class="adminftrIco"><i class="fas fa-running"></i></span
          ><span class="adminftrtXT">Exercises</span></a
        >
      </div>
      <div class="adminFtrCol">
        <a href="#" class="adminftrLnk"
          ><span class="adminftrIco"
            ><i class="fas fa-envelope-open-text"></i
            ><span class="ftrNOM">3</span></span
          ><span class="adminftrtXT">Messages</span></a
        >
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref, defineEmits } from "vue";
const currentYear = ref(new Date().getFullYear());
const isAddItemClicked = ref(false);
const emits = defineEmits(["addItemClicked"]);
const addItemHandler = () => {
  isAddItemClicked.value = !isAddItemClicked.value;
  emits("addItemClicked");
};
</script>
