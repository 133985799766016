<template>
  <div class="adwidmsg-item">
    <div class="adwidmsg-usrs">
      <img :src="require('@/assets/' + profileIcon + '')" class="msurImg" alt="{{ fullname }}" />
      <h4 class="msurName">{{ fullname }}</h4>
    </div>
    <div class="adwidmsg-msg">
      <p class="adwmsg-main">
        <span class="dat">{{ dateTime }}</span>
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, useSlots } from "vue";
const props = defineProps({
  profileIcon: String,
  fullname: String,
  dateTime: String,
});
const slots = useSlots();
</script>

<style lang="scss" scoped></style>
