<template>
  <div class="adminadd-item">
    <h4 class="adminadd">
      <slot name="icon"></slot>
      <span>{{ actionTitle }}</span>
    </h4>
    <span class="admad-ico"> <slot name="featuredIcon"></slot> </span>
    <a href="{{ link }}" class="adminaddnewlink">&nbsp;</a>
  </div>
</template>

<script setup>
import { defineProps, useSlots } from "vue";
const props = defineProps({
  actionTitle: String,
  link: String,
});
const slots = useSlots();
</script>

<style lang="scss" scoped></style>
