<template>
  <div class="site-admin">
    <NavbarLayout :is-toggled="isToggled" />
    <div class="admincontent-wrap" :class="{ hideContent: isToggled }">
      <TopBar :is-toggled="isToggled" @menu-button-clicked="toggleMenu" />
      <AnnouncementBar />
      <DashboardContent
        :is-toggled="isToggled"
        :is-add-item-clicked="isAddItemClicked"
      />
      <FooterLayout @add-item-clicked="addItemHandler" />
    </div>
  </div>
</template>

<script setup>
import NavbarLayout from "./NavbarLayout.vue";
import FooterLayout from "./FooterLayout.vue";
import TopBar from "./TopBar.vue";
import AnnouncementBar from "./AnnouncementBar.vue";
import DashboardContent from "./DashboardContent.vue";
import { ref } from "vue";
const isToggled = ref(false);
const isAddItemClicked = ref(false);
const toggleMenu = () => {
  isToggled.value = !isToggled.value;
};
const addItemHandler = () => {
  isAddItemClicked.value = !isAddItemClicked.value;
};
</script>

<style></style>
