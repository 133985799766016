<template>
  <div class="wcarea-wrap">
    <div class="wctxt-block">
      <h2 class="wc-title">Welcome back Bruce</h2>
      <p class="wctxt">
        You have 3 new messages, and 5 new entries in your feed.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
